<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="400px">
        <!-- 1111{{row.commodityType}} -->

    <el-form>
        <el-form-item label="电池二维码" >
      <!-- {{row}} -->
          {{ row.eleLink }}
        </el-form-item>
        <el-form-item label="通讯板编号">
          {{row.currentBoardNo}}
          <!-- {{ row.communicationBoardNo }} -->
        </el-form-item>

        <el-form-item
          label="核对电池编码"
          v-if="type == 1"
        >
        
        <!-- v-if="type == 1 && (row.status == 3 || row.status == 4) && row.commodityType == 1" -->
          <el-input
            v-model="communicationBoardNo"
            style="width: 250px"
            placeholder="请输入电池通讯板编号"
          ></el-input>
        </el-form-item>
        <!-- v-if="type == 2 && row.commodityType == 1" -->
        <el-form-item label="新电池编码" v-if="type ==2 ">
          <el-input
            v-model="communicationBoardNo"
            style="width: 250px"
            placeholder="请输入电池通讯板编号"
          ></el-input>
        </el-form-item>
      <div v-if="productType == 2">
        <!-- <el-form-item label="车辆二维码">
          {{ row.carLink }}
        </el-form-item> -->
        <el-form-item label="车辆中控号" v-if="type == 1 && (row.status == 3 || row.status == 4) && row.commodityType == 2">
          {{ row.udid }}
        </el-form-item>
        <el-form-item
          label="核对车辆编码"
          v-if="type == 1 && (row.status == 3 || row.status == 4) && row.commodityType == 2 "
        >
          <el-input
            v-model="udid"
            style="width: 250px"
            placeholder="请输入车辆中控号"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveHandle" :loading="disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { replaceBattery, closeLeaseOrder } from "@/api/order";
export default {
  props: {
    /** 电池类型 */
    productType: {
      default: 1
    },
    /** 1 关闭订单 2更换电池 */
    type: {
      default: 1
    },
    row: {
      default: () => {}
    }
  },
  data() {
    return {
      disabled: false,
      dialogVisible: false,
      /** 电池编号 */
      communicationBoardNo: "",
      /** 车辆编号 */
      udid: ""
    };
  },
  watch: {
    dialogVisible() {
      this.communicationBoardNo = "";
      this.udid = "";
    }
  },
  computed: {
    dialogTitle() {
      return this.type == 1 ? "结束订单" : "更换电池";
    }
  },
  methods: {
    async saveHandle() {
        // if (this.productType == 2 && !this.udid) {
        //   this.$message.error("请输入车辆信息");
        //   return;
        // }

        // if (this.productType == 1 && !this.communicationBoardNo) {
        //   this.$message.error("请输入电池信息");
        //   return;
        // }
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      const params = {
        subOrderNo: this.row.subOrderNo || this.$route.query.subOrderNo
      };
      if (this.productType == 1) {
        params.communicationBoardNo = this.communicationBoardNo;
      }
      if (this.productType == 2) {
        params.udid = this.udid;
        params.communicationBoardNo = this.communicationBoardNo;

      }
      try {
        let api = this.type == 1 ? closeLeaseOrder : replaceBattery;
        await api(params);
        this.disabled = false;
        this.close();
        this.$emit("success");
        this.$message.success("操作成功");
      } catch (error) {
        this.disabled = false;
      }
    },
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    }
  },
  components: {}
};
</script>

<style scoped></style>
