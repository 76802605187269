<template>
  <div>
    <h3>用户信息</h3>
    <el-table
      ref="lala1"
      class="mb20"
      :data="userInfo"
      style="width: 100%">
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <el-table-column label="应付总额" prop="payableMoney">
        <template slot-scope="{row}">
          <span>{{leaseOrder[0].payableMoney}}</span>
        </template>
      </el-table-column>
      <template v-if="+leaseOrder[0].status === 2 || +leaseOrder[0].status === 3 || +leaseOrder[0].status === 4|| +leaseOrder[0].status === 5">
        <el-table-column  label="实付总额" prop="payMoney"></el-table-column>
      </template>
    </el-table>
    <!--  -->
    <h3>商品信息</h3>
    <el-table
      class="mb20"
      :data="goodsData"
      style="width: 100%">
      <el-table-column
        v-for="item in goodsColumns"
        :key="item.label"
        :prop="item.prop"
        :label="item.label"
        width="180">
        <template slot-scope="{row}">
          <span v-if="item.label === '商品类型'">{{$changeObj(GOODS_TYPE)[row[item.prop]] }}</span>
          <span v-else>{{row[item.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--  -->
    <div class="dfjb topBox">
      <h3>订单信息</h3>
      <div>
        <el-button type="primary" @click="replaceBtn" size="mini"
        v-if="(leaseOrder[0].status == 3 || leaseOrder[0].status == 4) && leaseOrder[0].orderType != 3">更换电池</el-button>
        <el-button type="primary" @click="closeLeaseOrderBtn" size="mini"
        v-if="+leaseOrder[0].status === 4">结束订单</el-button>
      </div>
    </div>
    <el-table
      class="mb20"
      :data="leaseOrder"
      style="width: 100%">
      <el-table-column
        v-for="item in orderColumns"
        :key="item.label"
        :prop="item.prop"
        :label="item.label"
        width="200">
        <template slot-scope="{row}">
           <div v-if="item.label === '订单编号'" >
             <p >子：{{row.subOrderNo}}</p>
             <p >主：{{row[item.prop]}}</p>
           </div>
           <span v-else-if="item.prop === 'orderType'"  :class="[{'orange':+row[item.prop] === 2}]">{{$changeObj(ORDER_TYPE)[row[item.prop]]}}</span>
           <span v-else-if="item.prop === 'payType'">{{$changeObj(PAY_TYPE)[row[item.prop]]}}</span>
           <span v-else-if="item.prop === 'status'">{{$changeObj(ORDER_STATUS)[row[item.prop]]}}</span>
           <span v-else-if="item.prop === 'confirm'">{{$changeObj(CONFIRM_STATUS)[row[item.prop]]}}</span>
           <span v-else-if="item.prop === 'cashPledgeStatus'">{{$changeObj(CASH_PLE_STATUS)[row[item.prop]]}}</span>
           <span v-else-if="item.prop === 'createTime'">{{row[item.prop] }}</span>
           <span v-else-if="item.prop === 'leaseTime'">{{row[item.prop] }}</span>
           <span v-else>{{row[item.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--  -->
    <div class="dfjb topBox">
      <h3>分账信息</h3>
      <el-button type="primary" @click="closeAccountHandle" size="mini" v-if="separateFundStatus===0">关闭分账</el-button>
    </div>
    <!-- 关闭分账 -->
    <el-table
      class="mb20"
      :data="separateFund"
      style="width: 100%">
      <el-table-column
        v-for="item in separateFundColumns"
        :key="item.label"
        :prop="item.prop"
        :label="item.label"
        width="180">
        <template slot-scope="{row}">
          <span v-if="item.prop === 'direction'">{{$changeObj(DIRECTION_TYPE)[row[item.prop]]}}</span>
          <span v-else-if="item.prop === 'commodityType'">{{$changeObj(COMMODITY_TYPE)[row[item.prop]]}}</span>
          <span v-else-if="item.prop === 'balanceType'">{{$changeObj(ACCOUNT_TYPE)[row[item.prop]] }}</span>
          <span v-else>{{row[item.prop]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        :prop="closeSeparateReason"
        label="关闭分账原因"
        width="180">
        {{closeSeparateReason}}
      </el-table-column>
    </el-table>
    <div class="fe df">
      <el-button type="primary" @click="$router.push('/leaseholdMgmt/rent')">返回</el-button>
    </div>
    <el-dialog
      title="关闭分账"
      :visible.sync="closeAccountVisible"
      width="30%"
      >
      <p class="mb20">关闭原因</p>
      <el-input class="mb20" type="textarea" :rows="3" placeholder="请输入关闭原因" v-model="inpTextarea"/>
      <el-button type="primary" @click="closeSubAccount">确定</el-button>
    </el-dialog>
    <!-- 更换电池 -->
    <RentModal
      ref="rentModal"
      :type="type"
      @success='onSearch'
      :productType="productType"
      :row="row"
    ></RentModal>

  </div>
</template>

<script>
// import {queryLeaseOrderList} from "@/api/order"
import RentModal from "./components/RentModal";
import { queryLeaseOrderDetail ,replaceBattery,closeSubAccount,closeLeaseOrder} from "@/api/order";
import {GOODS_TYPE,ORDER_TYPE,PAY_TYPE,ORDER_STATUS,CONFIRM_STATUS,CASH_PLE_STATUS,
       CASH_PLEDGE_STATUS,COMMODITY_TYPE,ACCOUNT_TYPE,DIRECTION_TYPE} from "@/js/constant"
export default {
  data() {
    return {
      DIRECTION_TYPE,
      ACCOUNT_TYPE,
      COMMODITY_TYPE,
      GOODS_TYPE,
      ORDER_TYPE,
      PAY_TYPE,
      ORDER_STATUS,
      CONFIRM_STATUS,
      CASH_PLE_STATUS,
      CASH_PLEDGE_STATUS,
      type: 1 /* 1 结束订单 ， 2 更换电池 */,
      productType: 2,
      row: {},

      userInfo:[],
      userColumns:[
        {
          prop:'name',
          label:'姓名'
        },
         {
          prop:'phone',
          label:'手机号'
        },
         {
          prop:'payableMoney',
          label:'应付总额'
        },
         {
          prop:'payMoney',
          label:'实付总额'
        },
      ],
      goodsData:[],
      goodsColumns:[
        {
          prop:'commodityName',
          label:'商品名称'
        },
         {
          prop:'commodityType',
          label:'商品类型'
        },
         {
          prop:'specification',
          label:'商品规格'
        },
         {
          prop:'cashPledge',
          label:'押金'
        },
         {
          prop:'singleRent',
          label:'单价'
        },
         {
          prop:'buyNum',
          label:'数量'
        },
         {
          prop:'validDay',
          label:'有效时长'
        },
         {
          prop:'money',
          label:'总额'
        },
      ],
      orderColumns:[
        {
          prop:'orderNo',
          label:'订单编号'
        },
         {
          prop:'communicationBoardNo',
          label:'设备通讯板编号'
        },
         {
          prop:'agentName',
          label:'代理商'
        },
         {
          prop:'storeName',
          label:'门店'
        },
         {
          prop:'orderType',
          label:'订单类型'
        },
         {
          prop:'payType',
          label:'支付方式'
        },
         {
          prop:'status',
          label:'订单状态'
        },
         {
          prop:'confirm',
          label:'确认状态'
        },
        {
          prop:'cashPledgeStatus',
          label:'押金状态'
        },
        {
          prop:'totalActivityPlusMoney',
          label:'活动金额'
        },
        {
          prop:'totalGiveDay',
          label:'赠送天数'
        },
        {
          prop:'insuranceMoney',
          label:'保险金额'
        },
        {
          prop:'createTime',
          label:'创建时间'
        },
        {
          prop:'leaseTime',
          label:'租赁时间'
        },
      ],
      leaseOrder:[],
      separateFundColumns:[
        {
          prop:'number',
          label:'流水编号'
        },
        // {
        //   prop:'',
        //   label:'订单明细编号'
        // },
        {
          prop:'direction',
          label:'出账/入账'
        },
        {
          prop:'commodityType',
          label:'明细商品'
        },
        {
          prop:'balanceType',
          label:'账本类型'
        },
        {
          prop:'balanceUnitNoName',
          label:'账本组织'
        },
         {
          prop:'fundUnitNoName',
          label:'资金方/代理商/海狸组织'
        },
        {
          prop:'amount',
          label:'流水金额'
        }
      ],
      separateFund:[],
      subOrderNo:'',
      closeAccountVisible:false,
      inpTextarea:'',
      separateFundStatus:1,
      closeSeparateReason: ''
    }
  },
  mounted(){
    this.queryLeaseOrderDetail();
  },
  methods: {
    // 结束订单按钮
    closeLeaseOrderBtn(){

      this.row = this.$route.query;
      this.type = 1;
      this.productType = 2;
      this.$refs.rentModal.show();
    },
    // 回调
    onSearch(){
    },
    //关闭分账 确认按钮
    // closeConfirm(){

    // },
    // 关闭分账按钮
    closeAccountHandle(){
      this.closeAccountVisible = true;

    },
    //  关闭分账
    async closeSubAccount(){
      if(!this.inpTextarea){
        this.$message.warning('请输入关闭原因');
        return;
      }
      try{
        const params = {
          number:this.leaseOrder[0].subOrderNo,
          reason:this.inpTextarea,
        };
        const res = await closeSubAccount(params);
        this.$message.success('关闭分账成功');
        this.closeAccountVisible = false;
        this.queryLeaseOrderDetail();

      }catch(e){
        console.log(e,'e');
      }
    },
    // 列表
    async queryLeaseOrderDetail(){
       try{
         const params = {subOrderNo:this.$route.query.subOrderNo};
         const res = await queryLeaseOrderDetail(params);
         this.userInfo = [res.mdata.userInfo];
         this.goodsData = res.mdata.commodity;
         this.leaseOrder = [res.mdata.leaseOrder];
         this.separateFund = res.mdata.separateFund;
         this.separateFundStatus=res.mdata.separateFundStatus;
         this.closeSeparateReason=res.mdata.closeSeparateReason;

       }catch(e){
         console.log(e,'e');
       }
    },
    // 换电池按钮
    replaceBtn(){
      // this.replaceBattery();
      this.row = this.$route.query;
      this.type = 2;
      this.productType = 2;
      this.$refs.rentModal.show();
    },
    // 换电池请求
    // async replaceBattery(){
    //    try{
    //      console.log(this.leaseOrder[0],'this.leaseOrder[0]');
    //       const params = {
    //         communicationBoardNo:this.leaseOrder[0].communicationBoardNo,
    //         subOrderNo:this.$route.query.subOrderNo,
    //       };
    //       const res = await replaceBattery(params);
    //       console.log(res,'更换电池');
    //    }catch(e){
    //      console.log(e,'e');
    //    }
    // },
    // 结束订单
    // async closeLeaseOrder(){
    //   try{
    //     const params = {
    //       subOrderNo:this.$route.query.subOrderNo,
    //       udid:'',
    //       communicationBoardNo:this.leaseOrder[0].communicationBoardNo,
    //     };
    //     const res = await closeLeaseOrder(params);
    //     console.log(res,'关订单');
    //     this.$message.success('结束订单成功')
    //   }catch(e){
    //     console.log(e,'e');
    //   }
    // },
  },
  components: {
    RentModal,
  }
}
</script>

<style scoped>
  h3{
    background:#fff;
  }
  .topBox{
  }

</style>
