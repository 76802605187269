import axios from "../js/ajax";
/** 押金退款列表 */
export const queryRefundRecordList = params =>
  axios.get("/managementPlatform/lease/queryRefundRecordList", {
    params,
    loading: true
  });
/* 电池更换记录 */
export const queryElectricityReplaceRecordList = params =>
  axios.get("/managementPlatform/lease/queryElectricityReplaceRecordList", {
    params,
    loading: true
  });

/* 订单管理列表 */
export const queryLeaseOrderList = params =>
  axios.get("/managementPlatform/lease/queryLeaseOrderList", {
    params,
    loading: true
  });

/* 订单详情 */
export const queryLeaseOrderDetail = params =>
  axios.get("/managementPlatform/lease/queryLeaseOrderDetail", {
    params
  });
// 订单详情-更换电池按钮
export const replaceBattery = params =>
  axios.get("/managementPlatform/lease/replaceBattery", {
    params
  });
  // 关闭订单按钮  
  export const closeLeaseOrder = params =>
  axios.get("/managementPlatform/lease/closeLeaseOrder", {
    params
  });  
    // 申请退款  
export const applyReturnCash = params => axios.get("/managementPlatform/lease/confirmRefundCashPledge", { params });
 // 保险管理查询  
 export const queryBuyInsuranceRecordList = params => axios.get("/managementPlatform/lease/queryBuyInsuranceRecordList", { params });
// 关闭分账  
export const closeSubAccount = params => axios.get("/electricity/lease/closeSubAccount", { params });
// 租电订单导出按钮  
export const orderExport = params => axios.get("/electricity/lease/data/order/export", { params });

 